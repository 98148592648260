const NEWS_BANNER_ACKNOWLEDGEMENT_COOKIE_NAME = "news-banner-acknowledgement";

export function useNewsBanner() {
  const { data } = useLazyFetch("/api/news");
  const currentNews = computed(() => data.value?.[0]);

  const acknowledgement = useCookie(NEWS_BANNER_ACKNOWLEDGEMENT_COOKIE_NAME, {
    maxAge: 3600 * 24 * 365,
    default: () => "",
  });

  const displayNewsBanner = computed(() => {
    return (
      !!currentNews.value &&
      !acknowledgement.value?.includes(currentNews.value.id)
    );
  });

  function acknowledgeNewsBanner() {
    acknowledgement.value += `,${currentNews.value?.id}`;
  }

  return {
    acknowledgeNewsBanner,
    displayNewsBanner,
    currentNews,
  };
}
